import React from "react";
import bgImg from "../assets/salad.jpeg";

const Hero = () => {
  return (
    <div className="hero-wrapper relative h-screen w-screen flex items-center justify-center">
      <div className="absolute inset-0">
        <img src={bgImg} alt="Hero Background" className="object-cover w-full h-full" />
        <div className="absolute inset-0 opacity-50 rounded-br-[20rem]"></div>
      </div>
      <div className="relative z-10 md:absolute top-[-10%] md:top-[30%] md:right-10 bg-white/90 md:bg-[#86b97373]/20 p-8 rounded-lg shadow-xl max-w-[95%] sm:max-w-md">
        <h1 className="text-3xl font-semibold text-gray-700 mb-4">
          Nourish <span className="text-green-500">Dhara</span>
        </h1>
        <p className="text-lg text-gray-600 font-semibold">Your trusted companion in achieving a healthier lifestyle. Discover personalized wellness solutions tailored just for you.</p>
      </div>
    </div>
  );
};

export default Hero;
