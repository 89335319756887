import React from "react";
import profilePic from "../assets/profile_pic.jpeg";

const socialLinks = [
  {
    name: "Instagram",
    url: "https://www.instagram.com/nourishdhara/",
    icon: <i className="fa-brands fa-instagram"></i>,
  },
  {
    name: "Threads",
    url: "https://www.threads.net/@nourishdhara",
    icon: <i className="fa-brands fa-threads"></i>,
  },
  {
    name: "Facebook",
    url: "https://www.facebook.com/profile.php?id=100094915893902",
    icon: <i className="fa-brands fa-square-facebook"></i>,
  },
  {
    name: "LinkedIn",
    url: "https://www.linkedin.com/in/dt-anchal-bhardwaj-8552a7137",
    icon: <i className="fa-brands fa-linkedin"></i>,
  },
];

const NutritionistDetails = () => {
  return (
    <div className="nutritionist-section bg-gradient-to-r from-[#c5ddbd] to-[#8eba7e] min-h-screen flex items-center py-20">
      <div className="profile max-w-[95%] lg:max-w-5xl mx-auto relative bg-white rounded-lg shadow-lg p-8 md:flex">
        <div className="profile-image relative w-full md:w-1/2 mb-8 md:mb-0 md:mr-8 overflow-hidden rounded-lg">
          <img src={profilePic} alt="Dt. Anchal Bhardwaj" className="w-full h-auto md:h-full object-cover transform transition-transform duration-500 hover:scale-105" />
          <div className="absolute inset-0 bg-[#e5fad4] opacity-10 mix-blend-screen rounded-lg"></div>
        </div>

        <div className="profile-info w-full md:w-1/2 relative">
          <div className="md:hidden">
            <h1 className="first-name text-4xl font-bold">Dt. Anchal</h1>
            <h1 className="second-name text-4xl font-bold mt-2">Bhardwaj</h1>
          </div>
          <div className="hidden md:block absolute top-[-3rem] left-[-3rem]">
            <h1 className="first-name text-4xl font-bold">Dt. Anchal</h1>
            <h1 className="second-name text-4xl font-bold mt-2">Bhardwaj</h1>
          </div>

          <div className="relative pt-16 md:pt-6">
            <h2 className="sub-heading text-lg font-semibold text-[#43a047] mt-8 mb-4">ABOUT</h2>
            <p className="leading-relaxed mb-6">
              I'm Dt. Anchal Bhardwaj, a dedicated and certified dietitian with a comprehensive background in nutrition and dietetics. I am the founder of NourishDhara, a venture I launched a year ago
              to provide expert nutritional guidance and support to individuals dealing with various health conditions.
            </p>

            <h3 className="sub-heading text-lg font-semibold text-[#43a047] mb-2">Academic Background:</h3>
            <ul className="list-disc pl-6 mb-4">
              <li>BSc in Food and Nutrition</li>
              <li>MSc in Dietetics and Food Service Management</li>
              <li>Certifications in Food Allergies, Pregnancy and Postpartum Diet Planning, and Diabetes Education</li>
            </ul>

            <h3 className="sub-heading text-lg font-semibold text-[#43a047] mb-2">Professional Experience:</h3>
            <ul className="list-disc pl-6 mb-4">
              <li>Dietitian at Sahayta Nutrition</li>
              <li>Dietitian at Akash Hospital</li>
              <li>Dietitian at Dr. Ram Manohar Lohia (RML) Hospital</li>
            </ul>

            <p className="leading-relaxed mb-6">
              In these roles, I have developed and refined my skills in clinical nutrition, offering tailored dietary solutions and working closely with patients to improve their health outcomes.
            </p>

            <div className="social-media-icons flex mt-4 space-x-4">
              {socialLinks.map((link) => (
                <a key={link.name} href={link.url} target="_blank" rel="noopener noreferrer" className="text-2xl text-[#f63d47] hover:text-red-600 transition-colors duration-300">
                  {link.icon}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NutritionistDetails;
