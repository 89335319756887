import React, { useEffect, useRef, useState, useMemo } from "react";
import "./App.css";
import Loading from "./components/Loading";
import Hero from "./components/Hero";
import NutritionistDetails from "./components/NutritionistDetails";
import Details from "./components/Details";
import Recipes from "./components/Recipes";

const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 6000);

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="App">
      <div className="hero-wrapper">
        <div className="waves"></div>
        <Hero />
      </div>
      <div className="details-wrapper">
        <Details />
      </div>
      <div className="nutritionist-section">
        <NutritionistDetails />
      </div>
      <div className="recipes-section">
        <Recipes />
      </div>
    </div>
  );
};

export default App;
