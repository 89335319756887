import { ShoppingCartIcon } from "@heroicons/react/20/solid";
import recipes_cutlets from "../assets/recipes_cutlets.jpeg";
import recipes_dip from "../assets/recipes_dip.jpg";
import recipes_salad from "../assets/cucumberAvacado.jpg";
import recipes_chickpea from "../assets/recipe_chickpea.jpg";
import { ShoppingBagIcon } from "@heroicons/react/24/outline";

const recipesData = [
  {
    image: recipes_chickpea,
    title: "Spinach and Chickpea Salad",
    ingredients: [
      "1 cup fresh spinach leaves",
      "1 cup cooked chickpeas",
      "1/4 cup diced cucumber",
      "1/4 cup diced tomatoes",
      "1 tablespoon lemon juice",
      "1 tablespoon olive oil",
      "Salt and pepper to taste",
    ],
    instructions: [
      "In a bowl, combine spinach, chickpeas, cucumber, and tomatoes.",
      "Drizzle with lemon juice and olive oil. Season with salt and pepper, then toss to mix well.",
      "Season with salt and pepper, then toss to mix well.",
      "Serve immediately.",
    ],
  },
  {
    image: recipes_salad,
    title: "Cucumber and Avocado Salad",
    ingredients: ["1 cucumber, diced", "1 avocado, diced", "1 tablespoon lemon juice", "1 tablespoon olive oil", "Salt and pepper to taste"],
    instructions: ["In a bowl, combine cucumber and avocado.", "Drizzle with lemon juice and olive oil.", "Season with salt and pepper, then toss to mix well.", "Serve immediately."],
  },
  {
    image: recipes_cutlets,
    title: "Air-Fried Vegetable Quinoa Cutlets",
    ingredients: [
      "1 cup cooked quinoa",
      "1 cup grated carrot",
      "1 cup grated cucumber (squeezed dry)",
      "1/2 cup finely chopped spinach",
      "1 small onion, finely chopped",
      "2 tablespoons chickpea flour (besan)",
      "1 teaspoon cumin powder",
      "Salt and pepper to taste. Olive oil spray",
    ],
    instructions: [
      "Mix all ingredients in a bowl.",
      "Form mixture into patties.",
      "Preheat air fryer to 180°C (350°F).",
      "Place patties in the air fryer, spray with olive oil, and cook for 10-12 minutes, flipping halfway. Serve hot.",
    ],
  },
  {
    image: recipes_dip,
    title: "Paneer and Spinach Dip",
    ingredients: [
      "200 grams paneer, crumbled",
      "1 cup spinach, blanched and chopped",
      "1/2 cup Greek yogurt",
      "1 clove garlic, minced",
      "1 tablespoon lemon juice",
      "1 tablespoon olive oil",
      "Salt and pepper to taste",
    ],
    instructions: [
      "Mix crumbled paneer, chopped spinach, Greek yogurt, minced garlic, olive oil, lemon juice, salt, and pepper in a bowl.",
      "Chill in the refrigerator for 30 minutes.",
      "Serve with vegetable sticks or crackers.",
    ],
  },
];

const Recipes = () => {
  return (
    <div className="recipes mx-auto">
      {/* <header className="bg-white shadow">
        <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8 flex flex-col items-start">
          <h1 className="mb-4 text-4xl font-extrabold text-gray-900 dark:text-white">
            <span className="text-transparent bg-clip-text bg-gradient-to-r to-emerald-700 from-green-400">Recipes</span>
          </h1>
          <hr className="w-36 h-1 border-0 rounded bg-gradient-to-r to-emerald-700 from-green-400" />
        </div>
      </header> */}
      <blockquote className="text-4xl m-6 font-semibold italic text-center text-slate-900 py-8">
        <span className="before:block before:absolute before:-inset-1 before:-skew-y-3 before:bg-gradient-to-r to-green-600 from-green-500 relative inline-block before:rounded-sm">
          <span className="relative text-white p-8">Recipes</span>
        </span>
      </blockquote>
      {recipesData.map((recipe, index) => (
        <div className="relative isolate overflow-hidden bg-white px-6 py-20 sm:py-20 lg:overflow-visible lg:px-0" key={index}>
          <div className="absolute inset-0 -z-10 overflow-hidden">
            <svg
              aria-hidden="true"
              className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
            >
              <defs>
                <pattern x="50%" y={-1} id="e813992c-7d03-4cc4-a2bd-151760b470a0" width={200} height={200} patternUnits="userSpaceOnUse">
                  <path d="M100 200V.5M.5 .5H200" fill="none" />
                </pattern>
              </defs>
              <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
                <path d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z" strokeWidth={0} />
              </svg>
              <rect fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" width="100%" height="100%" strokeWidth={0} />
            </svg>
          </div>
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
            <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
              <div className="lg:pr-4">
                <div className="lg:max-w-lg">
                  {/* <p className="text-base/7 font-semibold text-indigo-600">Deploy faster</p> */}
                  <h1 className="mt-2 text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">{recipe.title} </h1>
                  {/* <p className="mt-6 text-xl/8 text-gray-700">
                  Ingredients:
                  </p> */}
                </div>
              </div>
            </div>
            <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
              <img src={recipe.image} alt={recipe.title} className="rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 max-h-96 max-h-[25rem] mr-auto lg:mr-0 w-full h-full object-cover" />
            </div>
            <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
              <div className="lg:pr-4">
                <div className="max-w-xl text-base/7 text-gray-700 lg:max-w-lg">
                  <p className="text-pretty text-xl font-semibold text-gray-900 flex gap-x-3">
                    <ShoppingCartIcon aria-hidden="true" className="mt-1 size-5 flex-none text-green-600" />
                    <span>Ingredients:</span>
                  </p>
                  <ul role="list" className="mt-4 space-y-2 text-gray-600">
                    {recipe.ingredients.map((ingredient, i) => (
                      <li className="flex gap-x-3 ml-5" key={i}>
                        <ShoppingBagIcon aria-hidden="true" className="mt-1 size-4 flex-none text-green-600" />
                        <span>
                          <strong className="font-semibold text-gray-900">{ingredient}</strong>
                        </span>
                      </li>
                    ))}
                  </ul>
                  <p className="text-pretty text-xl font-semibold text-gray-900 flex gap-x-3 mt-10">
                    <ShoppingCartIcon aria-hidden="true" className="mt-1 size-5 flex-none text-green-600" />
                    <span>Instructions:</span>
                  </p>
                  <ul role="list" className="mt-4 space-y-2 text-gray-600">
                    {recipe.instructions.map((instruction, i) => (
                      <li className="flex gap-x-3 ml-5" key={i}>
                        <ShoppingBagIcon aria-hidden="true" className="mt-1 size-4 flex-none text-green-600" />
                        <span>
                          <strong className="font-semibold text-gray-900">{instruction}</strong>
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Recipes;
