import React from "react";
import "./Loading.css";

const Loading = () => (
  <div className="relative w-screen h-screen overflow-hidden bg-gradient-to-r from-[#c5ddbd] via-[#8eba7e] to-[#99cc66]">
    <svg className="absolute">
      <defs>
        <filter id="goo">
          <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
          <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 15 -10" result="gooey" />
          <feBlend in="SourceGraphic" in2="gooey" />
        </filter>
      </defs>
    </svg>
    <div className="absolute inset-0 flex items-center justify-center">
      <div className="relative w-48 h-48 filter-goo" style={{ filter: "url(#goo)" }}>
        <p className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-6xl lg:tracking-normal tracking-wider md:text-[12rem] font-anton text-white/90  z-10">LOADING</p>
        <div
          className="drop-container absolute top-[0%] left-[41%] md:left-[35%] transform translate-x-[120%] w-[0.7rem] h-[0.7rem] md:w-[1.7rem] md:h-[1.7rem]"
          style={{ "--drop-size": "0.7rem" }}
        ></div>
      </div>
    </div>
  </div>
);

export default Loading;
