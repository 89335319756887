import React from "react";
import cardBg from "../assets/card-bg.jpg";
import diabetesBg from "../assets/diabetes.jpg";
import pcosBg from "../assets/pcosBg.jpeg";

const programData = [
  {
    title: "Weight Loss",
    text: "Personalized plans to help you lose weight safely and sustainably. A healthy lifestyle includes getting exercise every day along with eating a healthy diet. A healthy lifestyle may lower disease risks, such as obesity, heart disease, type 2 diabetes, hypercholesterolemia, hypertension, and cancer.",
    image: cardBg,
  },
  {
    title: "PCOS Management",
    text: "Expert guidance on managing PCOS through nutrition and lifestyle changes. My approach includes tailored diet plans that address the specific needs of women with PCOS, helping to manage symptoms and improve overall health.",
    image: pcosBg,
  },
  {
    title: "Diabetes Management",
    text: "Effective strategies to manage diabetes through diet. I offer comprehensive nutrition plans that help control blood sugar levels, improve insulin sensitivity, and promote overall well-being for individuals with diabetes.",
    image: diabetesBg,
  },
];

const Details = () => (
  <section className="py-12">
    <blockquote className="text-4xl m-8 font-semibold italic text-center text-slate-900 py-8">
      <span className="before:block before:absolute before:-inset-1 before:-skew-y-3 before:bg-gradient-to-r to-green-600 from-green-500 relative inline-block before:rounded-sm p-1">
        <span className="relative text-white p-6">Specialized Programs</span>
      </span>
    </blockquote>
    <div className="card-container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 max-w-5xl mx-auto">
      {programData.map((program, index) => (
        <div key={index} className="card-hover relative overflow-hidden rounded-lg shadow-lg group mx-2 lg:mx-0">
          <img src={program.image} alt={program.title} className="w-full h-full lg:max-h-none max-h-72 object-cover transition-transform duration-300 group-hover:scale-110" />
          <div className="card-hover__content absolute inset-0 bg-black bg-opacity-50 p-6 transition-all duration-300 group-hover:bg-opacity-70 font-sans">
            <div className="flex flex-col h-full items-center justify-center">
              <h3 className="card-hover__title text-2xl font-semibold mb-4 text-white text-center bg-white bg-opacity-20 px-4 py-2">{program.title}</h3>
              <p className="card-hover__text text-center text-white line-clamp-2 group-hover:line-clamp-none">{program.text}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  </section>
);

export default Details;
